<script setup lang="ts">
import type { HomeBanners } from "@/types";

const { banners, seoData, isShowAdventureBanner, jackpots, jackpotGames } = useHomePage();

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{ name: "keywords", content: () => seoData.value?.seoKeywords || "" }
	]
});

const isGuest = useIsGuest();
const { data: appInitData } = useAppInitData();

const isCatalogGames = useState<boolean>("show-catalog-games", () => false);

onMounted(() => {
	isCatalogGames.value = false;
});
</script>
<template>
	<div :class="['page', { 'is-logged': !isGuest }]">
		<LazyOHomeBanners v-if="banners?.length && banners.length > 0" :banners="banners" class="section-item no-hidden" />
		<LazyOGamesCatalog :show-catalog="isCatalogGames" />
		<div v-if="!isCatalogGames" class="section-item page">
			<LazyOHomeSectionGames :itemSection="0" />

			<OLazyComponent id="home-game-guest-must-try">
				<LazyOHomeActivityFeed />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="1" />

			<OLazyComponent id="home-promotions">
				<OHomePromotions />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="2" />

			<OLazyComponent v-if="isGuest" id="banner-wheel" class="wheels-banners">
				<LazyOHomeBannerWheel v-if="appInitData?.isShowDailyWheelForGuest" />
				<LazyOHomeBannerAdventure v-if="isShowAdventureBanner" />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="3" />

			<OLazyComponent v-if="jackpots" id="home-jackpot">
				<LazyOHomeJackpot :game-mode="appInitData?.gameMode" :jackpots="jackpots" :games="jackpotGames" />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="4" />

			<OLazyComponent v-if="isGuest" id="home-why-us">
				<LazyOHomeWhyUs />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="5" />

			<OLazyComponent v-if="isGuest && banners?.length && banners.length > 0" id="banner-footer">
				<LazyOHomeBannerFooter :banner="banners?.[0] as HomeBanners[number]" />
			</OLazyComponent>

			<LazyOHomeSectionGames :itemSection="6" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section-item {
	max-width: 100%;
	overflow: hidden;
}

.no-hidden {
	overflow: inherit;
}

.page {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		gap: 32px;

		&.is-logged {
			position: relative;
			padding-top: 8px;
		}
	}

	&:deep(.lazy-skeleton) {
		margin-top: 16px;
	}
	&.is-logged #fruits-games {
		margin-bottom: 32px;
	}
}

.wheels-banners {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 24px 0;

	@include media-breakpoint-down(md) {
		padding: 20px;
	}
}
</style>
